/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import Apps from "./apps.png";
import useWindowDimension from "hooks/use-window-dimensions";
import Icon from "./Test.png";

const AuthLayout = ({ children }) => {
  const mobileBreakpoint = 1220;
  const [width] = useWindowDimension();

  return (
    <Row style={{ overflow: "hidden", height: "100Vh" }}>
      <>
        {mobileBreakpoint < width ? (
          <Row style={{ width: "100%" }}>
            <div
              style={{
                width: "50vw",
                background: "linear-gradient(to left top, #5609A4, #BE18A8)",
                paddingTop: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={Icon} style={{ width: "70%" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <img src={Apps} style={{ width: 400 }} />
                </div>
              </div>
            </div>
            <div style={{ width: "50vw", padding: "10%" }}>{children}</div>
          </Row>
        ) : (
          <>
            <div style={{ width: "100vw", padding: 50 }}>
              <div>{children}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <img src={Icon} style={{ width: "100%" }} />
              </div>
            </div>
          </>
        )}
      </>
    </Row>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AuthLayout;
