import dotenv from "dotenv";
import urljoin from "url-join";

dotenv.config();

const env = {
  development: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
};

const backend = {
  AUTH_URI: process.env.REACT_APP_SERVER_URI,
  HTTP_URI: urljoin(process.env.REACT_APP_SERVER_URI ?? "", "graphql"),
  WS_URI: urljoin(process.env.REACT_APP_WS_URI ?? "", "graphql"),
  CHATBOT_URI: process.env.REACT_APP_CHATBOT_URI,
  ULTRAMSG_URL: process.env.REACT_APP_ULTRAMSG_URL,
  CHATBOT_TABLE: urljoin(
    process.env.REACT_APP_SERVER_URI ?? "",
    "chatbot_table"
  ),
};

const localURL = process.env.REACT_APP_LOCAL_URL;

const publicNamespace = process.env.REACT_APP_PUBLIC_NAMESPACE;

export { env, backend, publicNamespace, localURL };
