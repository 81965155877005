/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Layout as Layer, Card, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Sidebar from "layouts/main/sidebar";
// import Navbar from "layouts/main/navbar";
// import Footer from "layouts/main/footer";

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
      setCollapsed(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layer style={{ minHeight: "100vh", maxHeight: "100vh" }}>
      <Layer
        style={{
          background: "linear-gradient(to bottom, #342b58 14%, #1f1b3e 76%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {isMobile && (
          <Button
            type="primary"
            icon={<MenuOutlined />}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            style={{
              display: collapsed ? "block" : "none",
              position: "absolute",
              top: 25,
              left: 25,
              zIndex: 10,
            }}
          />
        )}
        {!collapsed && (
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            isMobile={isMobile}
          />
        )}
        {((isMobile && collapsed) || !isMobile) && (
          <Card
            style={{
              margin: 15,
              height: "96vh",
              width: isMobile ? "100vw" : "90vw",
              overflow: "scroll",
              borderRadius: 20,
              paddingTop: isMobile && collapsed ? 25 : undefined,
            }}
            bodyStyle={{
              padding: "0",
            }}
          >
            {/* <Navbar /> */}
            {children}
          </Card>
        )}

        {/* <Footer /> */}
      </Layer>
    </Layer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MainLayout;
