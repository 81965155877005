import { WebSocketLink } from "@apollo/client/link/ws";
import { backend } from "config/environment";
import cookie from "react-cookies";

const wsLink = new WebSocketLink({
  uri: backend.WS_URI,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      const token = cookie.load("token");
      return {
        authorization: token ? `Bearer ${token}` : "",
      };
    },
  },
});

export default wsLink;
