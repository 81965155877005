import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

const ChatBot = lazy(() => import("./chatbot"));
const OnBoarding = lazy(() => import("./onBoarding"));
const ChatBotNew = lazy(() => import("./chatbotNew"));

const Public = () => {
  return (
    <Switch>
      <Suspense fallback={<TopBarProgress />}>
        <Route path="/chatbot" component={ChatBot} />
        <Route path="/onBoarding" component={OnBoarding} />
        <Route path="/chatbotNew" component={ChatBotNew} />
      </Suspense>
    </Switch>
  );
};

export default React.memo(Public);
// Comment for push
