import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import cookies from "react-cookies";
import wsLink from "./links/ws";
import httpLink from "./links/http";
import { setContext } from "@apollo/client/link/context";
import { publicNamespace } from "../config/environment";

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext(({ operationName }, { headers }) => {
  const token = cookies.load("token");
  const validPublicOperations = ["validCoordinates", "clientUpdateDesination"];
  return {
    headers: validPublicOperations.includes(operationName)
      ? {
          ...headers,
          "public-namespace": publicNamespace,
          authorization: token ? `Bearer ${token}` : "",
        }
      : {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // TODO: Implement merging strategies for fields.
          users: { merge: false },
          tickets: { merge: false },
        },
      },
    },
  }),
});

export default client;
