import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import client from "graph-ql";
import App from "./app";
import "antd/dist/antd.less";
import theme from "config/theme";
import { ConfigProvider } from "antd";
import "moment/locale/es";
import esES from "antd/lib/locale/es_ES";
import "./index.css";
import { UserProvider } from "providers/user";

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router basename="/">
          <ConfigProvider locale={esES}>
            <App />
          </ConfigProvider>
        </Router>
      </UserProvider>
    </ThemeProvider>
  </ApolloProvider>,

  document.getElementById("root")
);
