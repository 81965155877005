import React from "react";
import { withRouter } from "react-router-dom";
import Public from "../public";
import MainLayout from "layouts/main";
import AuthLayout from "layouts/auth";
import Authentication from "views/private/routes/authentication";
import CRM from "views/private/routes/crm";
import { useUser } from "providers/user";
import { useLocation } from "react-router-dom";

const Private = () => {
  const currentLocation = useLocation();
  const orderId = new URLSearchParams(currentLocation.search).get("order_id");
  const isOnBoarding = orderId !== null;
  const { user } = useUser();

  const publicRoutes = ["/chatbot", "/onBoarding", "/chatbotNew"];

  if (publicRoutes.includes(location.pathname)) return <Public />;

  if (!user) {
    return (
      <AuthLayout>
        <Authentication />
      </AuthLayout>
    );
  } else {
    return (
      <MainLayout user={user}>
        <CRM orderId={orderId} isOnBoarding={isOnBoarding} />
      </MainLayout>
    );
  }
};

export default withRouter(Private);
