import { gql } from "@apollo/client";

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      organization {
        id
        name
        logo
        apiKey {
          secret
        }
        chatbots {
          id
          name
          type
        }
      }
      type
    }
  }
`;

export { GET_USER };
